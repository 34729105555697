@import '@/assets/styles/variables.scss';

.select {
  /* antd overrides*/

  .ant-select {
    &.-selected > .ant-select-selector {
      background-color: #fff;
    }

    &.-selected > .ant-select-selector > * {
      color: #0c2e78;
    }

    &.-selected > .ant-select-arrow {
      color: #0c2e78;
    }

  }

  .ant-select,
  .ant-select > .ant-select-selector {
    background-color: #0c2e78;
    border-radius: 0;
    color: #fff;
    font-size: 0.8rem;
    width: 100%;
  }

  .ant-select > *,
  .ant-select > .ant-select-selector > * {
    border: none;
    color: #fff;
    outline: none;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select > .ant-select-selector > .ant-select-selection-placeholder {
    color: #d9d9d9;
  }
  .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > .ant-space
    > .ant-space-item {
    display: flex;
  }
}

/* antd overrides*/
.ant-select-dropdown {
  border-radius: 0;
  padding: 0;

  .ant-select-item {
    border-radius: 0;
    border: 1px solid $deep-sapphire;
  }
}
