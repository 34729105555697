.toast-notification {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 398px;
  padding: 1rem;

  background: #fff;
  box-shadow: 0px 4px 4px 0px #ccd2e3;
}
