.w-100 {
  width: 100%;
}

.m-auto {
  margin: 0 auto;
}

.--hidden {
  display: none;
}

.--readonly {
  pointer-events: none;
  cursor: not-allowed;
}

.flex {
  display: flex;
  align-items: center;

  &.-center {
    justify-content: center;
  }

  &.-space-between {
    justify-content: space-between;
  }

  &.-end {
    justify-content: flex-end;
  }

  &.-gap-1 {
    gap: 0.5rem;
  }

  &.-column {
    flex-direction: column;
  }
}

// .flex-1 to .flex-5
$flexMax: 5;
@for $i from 1 through $flexMax {
  .flex-#{$i} {
    flex: $i;
  }
  .gap-#{$i} {
    gap: #{$i * 2}px;
  }
}
