@import '@/assets/styles/variables.scss';

.profile-edit-page {
  background: white;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: scroll;

  &.-mobile.-keyboard-open {
    padding-bottom: 70vh;
  }

  > .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    background: #143a79;

    > .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > .header > .title {
      font-size: 23px;
      color: white;
    }

    > .header > .spacer {
      width: 48px;
    }

    > .tile-container {
      width: 50%;
      margin: 1rem auto;
    }
  }

  > .virtual-title {
    display: block;
    padding-block: 8px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #0c2e78;
    margin-left: 14px;
  }

  > .link {
    font-weight: bold;
    color: $deep-sapphire;
  }

  .profile-edit-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    gap: 10px;
    padding: 12px;

    > .profile-name {
      margin-top: 4px;
      height: 40px;
      width: 380px;
      font-size: 23px;
      color: #143a79;
      border-bottom: 2px solid #92aad0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > .business-card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
  }

  > .business-card-section > .virtual-card {
    padding: 0 12px;
    margin-bottom: 0.375rem;
  }

  > .accordions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 12px;
  }

  > .accordions > .accordion > .body > .actions {
    margin-top: 4px;
  }

  > .accordions > .accordion > .body > .accordion {
    margin-top: 0.5rem;
  }

  > .accordions > .business-card-photo > .body > .business-card-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  > .button-container {
    padding: 0 12px;
    margin-top: 16px;
    margin-bottom: 18px;
  }
}

.general-loader {
  display: flex;
  flex-direction: column;
  text-align: center;

  > p {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
  }
}

.generic-label {
  display: block;
  padding-block: $spacing-sm;

  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-note-field {
  padding: 12px;
  color: $deep-sapphire;
}

.note-field {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}

.note-button-edit {
  display: table;
  margin-left: auto;
  margin-right: 2px;
}

.note-shadow-field {
  padding: 8px;
}
