@import '../../assets/styles/variables.scss';

.input-field {
  width: 100%;
  color: $deep-sapphire;

  > .-error {
    color: #ff0000;
    font-size: 15px;
  }

  > .label {
    display: block;
    padding-block: $spacing-sm;

    font-size: $font-size-sm;
    font-weight: bold;
    text-transform: uppercase;
  }

  .password-container {
    display: flex;
    flex-direction: column;

    > .password {
      position: relative;
      display: flex;
      align-items: center;

      > input[type='password']::-ms-reveal,
      input[type='password']::-ms-clear {
        display: none;
      }
    }

    > .password > button {
      padding-inline: $spacing-xs;
      background-color: transparent;
      color: #0c2e78;
      position: absolute;
      right: 0;
      transform: translate(-15px, 8%);
    }
  }

  .input {
    width: 100%;
    padding: 13px $spacing-md;

    font-size: 1.125rem;
    font-weight: 500;
    box-shadow: 0px 4px 10px rgba(64, 75, 92, 0.1);
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }

  .input.-required.-non-empty,
  .input.-optional.-non-empty {
    background-color: white;
    color: $deep-sapphire;
  }

  .input.-required.-empty {
    background-color: $deep-sapphire;

    // old
    color: white;
  }

  .input.-optional.-empty {
    background-color: rgba(12, 46, 120, 0.5);
  }

  .input.-required.-empty::placeholder,
  .input.-optional.-empty::placeholder {
    color: white;
  }
}
