@import '../../assets/styles/variables.scss';

.blank-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $font-main;
  font-size: 1.25rem;
  text-align: center;
  margin: 25px 0;
}
