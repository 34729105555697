@import '@/assets/styles/variables.scss';

.menu-group {
  > .title {
    font-family: Inter;
    font-size: 1rem;
    padding: .5rem 0;
  }
  > hr {
    color: #fff;
    margin-bottom: .5rem;
  }
}
