@use "sass:list";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Padding and Margin
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;
$spacing-xl: 64px;

// Font Size
$font-size-xs: 8px;
$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;

// Font Weight

// Font Family
@font-face {
  font-family: 'Inter';
  src: url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
}

$font-main: 'Inter', sans-serif;

// Color
$deep-ocean: linear-gradient(180deg, #07193f 0%, #0c2e78 100%);
$deep-cove: #07193f;
$deep-sapphire: #0c2e78;
$san-marino: #4465ad;
$danube: #5887cc;
$chetwode-blue: #87a9e0;
$blue-haze: #ccd2e3;

$cranberry: #dd647a;

$boulder: #7c7c7c;
$botticelli: #cfd5e5;
$gallery: #ebebeb;
$white: #fff;

// shadow
$boxShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// Hex shape
$hex-tile-aspect-ratio: list.slash(1, 0.86);
$hex-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

$business-card-aspect-ratio: list.slash(3.5, 2);

$navbar-height: 44px;
