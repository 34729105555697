@import '@/assets/styles/variables.scss';

.button-icon {
  & {
    min-width: 32px;
    min-height: 32px;
    padding: $spacing-xs $spacing-sm;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
  }

  > .material-symbols-outlined {
    font-size: 32px;

    &.-fill {
      font-variation-settings: 'FILL' 1;
    }
  }
}

.button-icon-v2 {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 6px 8px;

  &.-deep-blue {
    background-color: $deep-sapphire;
    color: white;
  }

  &.-labeled {
    > span:first-child {
      font-size: 24px;
      border-right: 1px solid white;
      padding-right: 5px;
    }

    > span + span {
      padding-left: 5px;
    }
  }
}

.button-icon.-c-deep-sapphire {
  > .material-symbols-outlined {
    color: $deep-sapphire;
  }
}

.button-icon.-c-white {
  > .material-symbols-outlined {
    color: white;
  }
}

.button-icon.-c-boulder {
  > .material-symbols-outlined {
    color: $boulder;
  }
}

.button-icon.-has-border {
  & {
    border-inline: 1px solid white;
  }
}
