@import '@/assets/styles/variables.scss';

.material-symbols-outlined {
  font-size: 32px;

  &.-primary {
    color: $deep-sapphire;
  }

  &.-small {
    font-size: 1.3rem;
  }

  &.-button {
    cursor: pointer;
  }

  &.-white {
    color: $white;
  }
}
