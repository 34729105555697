@import '@/assets/styles/variables.scss';

.dropdown {
  > .toggle {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $deep-sapphire;
    color: white;
  }

  > .toggle > .icon-container {
    flex: 0 0 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #bababa;
  }

  > .toggle > .icon-container > .icon {
    background-color: $deep-sapphire;
    color: white;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease;
  }

  > .toggle > .icon-container > .-default {
    transform: rotate(0deg);
  }

  > .toggle > .icon-container > .-active {
    transform: rotate(180deg);
  }

  > .toggle > .icon {
    max-width: 27px;
    height: auto;
    margin-left: 12px;
  }

  > .toggle > .icon > img {
    width: 100%;
    height: auto;
  }

  > .toggle > .text {
    margin-left: 22px;
    line-height: 16px;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
  }

  > .menu {
    overflow: hidden;
    height: 0;

    transition-property: height, opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease;

    &.-shown {
      height: 100%;
    }

    &.-hidden {
      height: 0;
    }
  }

  .menu > .close {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-color: $blue-haze;
    color: white;
  }
}

.dropdown.-inactive {
  pointer-events: none;
}

.dropdown.-inactive > .toggle,
.dropdown.-inactive > .toggle .icon {
  background: grey;
}
