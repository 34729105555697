@import '@/assets/styles/variables.scss';

.info-block {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;

  > .tile-container {
    padding-block: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.625rem;
  }

  > .tile-container > .hex-tile-wrapper {
    flex: 0.5;
  }

  > .tile-container > .spacer {
    width: 44px;
    height: 100%;
    padding-inline: 0.375rem;
  }

  > .tile-container > .spacer > .edit {
    cursor: pointer;
  }

  > .name {
    padding-block: 0.75rem;
    border-bottom: 1px solid #ccd2e3;
    text-align: center;
    line-height: 16px;
    font-size: 20px;
    font-weight: 700;
    font-weight: bold;
    color: #163475;
    text-transform: capitalize;
    letter-spacing: 0.4px;
  }

  > .profession {
    height: 75px;
    padding: 0.625rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: linear-gradient(90deg, #f1f1f1 0%, $white 100%);
    line-height: 24px;
    font-size: 16px;
    color: #000;

    > .position {
      font-weight: 400;
    }

    > .company {
      font-weight: 700;
      font-weight: bold;
    }
  }
}
