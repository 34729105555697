@import '../../assets/styles/variables.scss';

.return-bar {
  font-family: $font-main;
  display: flex;
  align-items: center;
  background-color: $deep-sapphire;
  padding: 4px 0;
  margin-bottom: 2px;
  color: #fff;

  &.-bg-white {
    background-color: #fff;
  }

  &.-shadowed > .action {
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  }

  > .action {
    border-right: 1px solid #fff;
    margin-right: 10px;
  }

  > .action > button {
    display: flex;
    justify-content: center;
    padding: 6px 4px;
    background: none;
    border: none;
    color: #fff;
  }

  > .action > button > span {
    color: #fff;
    font-size: 1.5rem;
  }
}
