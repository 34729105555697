@import '@/assets/styles/variables.scss';

.toast-notification {
  > .icon-container {
    width: 36px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: $hex-clip-path;
    align-self: flex-start;
    margin-top: 8px;

    &.-success {
      background-color: #032b7a;
    }

    &.-primary-info {
      background-color: #6899c8;
    }

    &.-secondary-info {
      background-color: #6de1d8;
    }

    &.-warning {
      background-color: #eba048;
    }

    &.-error {
      background-color: #e42525;
    }
  }

  > .icon-container > .icon {
    font-size: 20px;
    color: white;
  }

  > .message {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &.-reconnection {
      gap: 0.75rem;
    }
  }

  > .message > .header,
  > .message .primary {
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #0c307e;
  }

  > .message > .body {
    display: flex;
    gap: 0.5rem;
  }

  > .message.-reconnection > .body {
    flex-direction: column;
    gap: 0.75rem;
  }

  > .message > .body,
  > .message .secondary,
  > .message.-reconnection > .body > .requester-info {
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
  }

  > .message .date-time {
    line-height: 20px;
    font-size: 12px;
    font-weight: 200;
    color: $boulder;
  }

  > .message.-reconnection .secondary {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }

  > .message.-reconnection > .body > .link-container {
    display: flex;
    gap: 0.5rem;
  }

  > .message.-reconnection > .body > .link-container > .link {
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #0c307e;
    text-decoration-line: underline;
    cursor: pointer;

    &.-active {
      color: #7a7a7a;
    }
  }

  > .action {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  > .action > .close-btn {
    align-self: flex-end;
    font-size: 24px;
    color: #ccd2e3;
    cursor: pointer;
  }

  > .action > .respond {
    padding: 0.5rem 0.625rem;
    background-color: #0c307e;
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.24px;
    text-transform: capitalize;
  }
}
