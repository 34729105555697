@import '../../assets/styles/variables.scss';

.rich-text-field {
  border: 1px solid $boulder;
  margin: 0.5rem 0;

  > .textarea {
    background: white;
    min-height: 6em !important; // Slate is has default mih-height when having a placeholder
    padding: 1em;
    font-size: 14px;

    > ol,
    ul {
      padding: 0 1em;

      > li {
        list-style-position: inside;
      }
    }
  }

  > .rich-text-actions {
    margin: 0.5rem 0.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2px;

    > .grouping {
      display: flex;
      justify-content: space-between;
      gap: 2px;
      flex: 1;
      padding: 0 0.5rem;
    }
  }

  .format-button {
    padding: 4px;
    background-color: white;
    border: 1px solid $boulder;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;

    &.-active {
      background: #ccc;
    }

    > .material-symbols-outlined {
      font-size: 2em;
    }
  }
}

.rich-text-field-content {
  padding: 0.5rem 0.625rem;
  font-size: 14px;

  > .textarea {
    background-color: #fff;
    border: none;
    padding: 1rem;
    outline: none;

    > ul, ol {
      padding-left: 24px;
    }
  }
}
.blurry{
  color: #7c7c7c;
}