@import '@/assets/styles/variables.scss';

.skill-item {
  margin-top: 1rem;

  > .header {
    box-shadow: none;
    border-top: 1px solid #0c307e;
    border-bottom: 1px solid #0c307e;

    font-size: 0.875rem;

    padding: 0;

    > .label {
      font-size: 0.875rem;
    }

    > .icon {
      margin: 0 0.5rem;
    }
  }

  .skill-item-stat {
    > .circle {
      background-color: $danube;
      border-radius: 50%;
      margin: 0 0.375rem;
      width: 12px;
      height: 12px;
    }
  }

  .skill-item-header {
    color: #000;
    font-family: 'Inter';
    border-left: 1px solid #bfbfc1;
    border-right: 1px solid #bfbfc1;
    padding: 1.125rem 0.625rem 1.125rem 3.125rem;
    flex: 1;
  }
}
