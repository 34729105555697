@import '@/assets/styles/variables.scss';

.business-card-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  width: 100%;
}

.business-card-item {
  background-color: #fff;
  padding: 5px;
  aspect-ratio: $business-card-aspect-ratio;
  width: 100%;
  overflow: hidden;

  > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    aspect-ratio: $business-card-aspect-ratio;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  > .content > .default-view {
    padding: 10px;
    width: 100%;

    > .name {
      border-bottom: 1px solid #d8ddea;
      font-size: 1rem;
      font-weight: bold;
      padding-bottom: 0.3rem;
      margin-bottom: 0.3rem;
      text-align: center;
    }

    > p {
      font-size: 1rem;
      font-style: italic;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > .content > img {
    width: 100%;
  }
}
