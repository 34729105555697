@import '@/assets/styles/variables.scss';

.menu-option {
  background-color: #fff;
  box-shadow: 0px 4px 0px 0px #334365;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
  overflow: hidden;
  text-transform: none;

  &.-list {
    overflow: unset;
    align-items: start;
    min-height: 40px;
    height: auto;
    background-color: $deep-cove;

    > .icon-container {
      min-width: 60px;
      max-width: 60px;
    }

    > .items {
      width: 100%;
      background-color: #fff;
    }

    > .items > .accordion {
      width: 100%;

      > .header {
        padding: 0.25rem 0.5rem;
        color: #767b87;
        box-shadow: none;
        line-height: 1;
      }

      > .header > .label {
        color: #000;
        font-size: 1em;
        font-weight: 600;
      }

      > .body {
        box-shadow: none;
      }

      > .body > ul {
        list-style-type: none;
        border-top: 1px solid #334365;
        margin: 0 0.5rem;
        padding: 0 1rem;
      }

      > .body > ul > li {
        cursor: pointer;
        color: #000;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 40px;
        border-bottom: 1px solid #c1c4cb;
        padding: 0.7rem 0;
        font-weight: 600;

        > .radio {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          border: 1px solid #767b87;

          &.-active::before {
            content: '';
            display: block;
            position: absolute;
            width: 9px;
            height: 9px;
            background-color: #767b87;
            border-radius: 50%;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  > .text {
    color: #000;
    font-weight: 600;
    padding: 0.5rem;
    font-size: 1em;
  }

  > .icon-container {
    background-color: $deep-cove;
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.-main {
      border: 1px #fff solid;
    }
  }
}
