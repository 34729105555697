@import '@/assets/styles/variables.scss';

.contact-list-item + .contact-list-item {
  margin-top: 8px;
}

// antd overrides
.contact-list-item {
  .ant-card {
    border-radius: 0;
    padding: 0;
  }
  .ant-card-body {
    padding: 0.3rem;
  }
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  position: relative;

  > .img-container {
    flex: 0 0 58px;
  }

  > .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > .status {
    align-self: stretch;
    width: 12px;
  }

  > .status.-pretile {
    background-color: $deep-sapphire;
  }
}

.contact-item > .content {
  overflow: hidden;

  > .name {
    color: $deep-sapphire;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    padding-bottom: 2px;
  }

  > hr {
    border-bottom: 1px solid $deep-sapphire;
  }

  > .bottom {
    display: flex;
    padding-top: 2px;
    min-height: 22px;
  }

  > .bottom > span {
    font-size: 0.8rem;
    white-space: nowrap;
    word-break: break-word;

    &:last-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
