@import '@/assets/styles/variables.scss';

.loader {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  height: 100%;
  background-color: #fff;

  > img {
    width: 100%;
  }
}
