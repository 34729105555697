.delete-confirmation {
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1003;

    width: 380px;
    height: 100%;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    background-color: white;
    box-shadow: 0 4px 4px gray;
  }

  > .confirmation-query {
    text-align: center;
  }

  > .data {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;

    border: 2px solid #EDEDED;
  }

  > .additional-info {
    font-size: 12px;
  }

  > .cta-buttons {
    width: 100%;
    display: flex;
    gap: 24px;
  }

  > .cta-buttons > .cancel-button,
  > .cta-buttons > .confirm-button {
    flex: 1;
    padding: 8px;

    background-color: white;
    border: 1px solid #BABABA;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }

  > .cta-buttons > .confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
  }

  > .cta-buttons > .confirm-button > .icon {
    color: rgb(142, 142, 194);
  }
}
