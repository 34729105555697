@import '../../assets/styles/variables.scss';

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* antd overrides*/
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none;

  &.-sm {
    width: 30%;
    margin-left: auto;
  }

  &.-with-icon {
    gap: 8px;
  }

  &.-no-gradient {
    &::before {
      height: 0 !important;
    }
  }

  &.-hidden {
    display: none;
  }

  &.next-btn{
    height: auto;
    justify-content: flex-end;

    font-weight: 500;
  }

  &.ant-btn-primary {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      width: 100%;
      background-image: linear-gradient(
        to top,
        #0c2e78,
        rgba(255, 255, 255, 0.37)
      );
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8px;
      width: 100%;
      background-image: linear-gradient(
        to top,
        #1f176f,
        rgba(255, 255, 255, 0)
      );
    }

    &.-solid {
      background-color: #0e2f66;

      &::after,
      &::before {
        content: none;
      }
    }
  }

  &.ant-btn-default {
    background-color: #afb3bc;
    color: #fff;
    height: auto !important;
  }

  &.-large {
    font-size: 1.5rem !important;
    height: auto !important;
    padding: 0.875rem;
  }

  &.-muted,
  &:disabled {
    background-color: #a0a3aa;
    color: white;

    &::before {
      background-image: linear-gradient(
        to top,
        #a0a3aa,
        rgba(255, 255, 255, 0.37)
      );
    }

    &::after {
      background-image: linear-gradient(
        to top,
        #a0a3aa,
        rgba(255, 255, 255, 0)
      );
    }
  }
}
