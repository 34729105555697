@import '@/assets/styles/variables.scss';

.experience-item {
  margin-top: 1rem;

  .header {
    box-shadow: none;
    border-top: 1px solid #0c307e;
    border-bottom: 1px solid #0c307e;

    font-size: 0.875rem;

    padding: 0;

    > .icon {
      margin: 0 0.5rem;
    }
  }

  .experience-item-stat {
    > .circle {
      background-color: #0c307e;
      border-radius: 50%;
      margin: 0 0.375rem;
      width: 12px;
      height: 12px;
    }
  }

  .experience-item-header {
    font-family: 'Inter';
    border-left: 1px solid #bfbfc1;
    border-right: 1px solid #bfbfc1;
    padding: 0.5rem 0.625rem 0.313rem 3.125rem;
    flex: 1;
  }

  .experience-item-header .title {
    color: #000;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .experience-item-header .date {
    color: $deep-sapphire;
    font-size: 0.75rem;
    font-style: italic;
  }

  > .body {
    box-shadow: none;
  }
}
