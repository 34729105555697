@import '@/assets/styles/variables.scss';

.hex-tile-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-block: $spacing-sm;
  margin: 0 auto;

  > .column {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.hex-tile-wrapper {
  position: relative;
  width: 100%;

  &.-outgoing,
  &.-incoming {
   > .hex-tile > .outer > .inner {
      background-color: #edb729;
    }
  }

  > .border {
    position: absolute;
    clip-path: $hex-clip-path;
    width: 108%;
    height: 108%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > .badge {
    position: absolute;
    top: calc(100% - (100% * 0.255) - 3%);
    left: 54.8%;
    width: 25%;
    height: calc(100% * 0.255);
    background-color: #d9d9d9;

    clip-path: $hex-clip-path;
    z-index: 8;

    > img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

    > .outer {
      position: absolute;
      width: 99.5%;
      height: 99.5%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      background-color: #fff;

      clip-path: $hex-clip-path;
    }

    > .outer > .inner {
      position: absolute;
      width: 94%;
      height: 94%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      background-color: $deep-sapphire;
      color: #fff;
      overflow: hidden;

      clip-path: $hex-clip-path;
    }

    > .outer > .inner > .content {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }
  }

  > .overlaying-triangle {
    position: absolute;
    top: calc(100% - (100% * 0.255) - 42%);
    left: 12.5%;
    width: 20%;
    height: calc(75% * 0.255);
    background-color: red;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    z-index: 9;
  }

  > .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: -2%;
    background-color: #d9d9d9;

    clip-path: $hex-clip-path;
    z-index: 1;
  }
}

.hex-tile {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  aspect-ratio: $hex-tile-aspect-ratio;

  background-color: #d9d9d9;
  color: #fff;
  word-wrap: break-word;

  clip-path: $hex-clip-path;
  z-index: 2;

  > .outer {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: $hex-tile-aspect-ratio;

    clip-path: $hex-clip-path;
  }

  > .outer > .bg {
    position: absolute;
    height: 50%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &.-top {
      top: 0;

      > .triangle:first-child {
        background-color: #d9d9d9;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }

      > .triangle:nth-child(2) {
        background-color: #fff;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }

      > .triangle:last-child {
        background-color: #fff;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }

    &.-bottom {
      bottom: 0;

      > .triangle:first-child {
        background-color: #d9d9d9;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }

      > .triangle:nth-child(2) {
        background-color: #9e9e9e;
        clip-path: (50% 0, 100% 100%, 0 100%);
      }

      > .triangle:last-child {
        background-color: #7a7a7a;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }
    }

    > .triangle {
      width: 50%;
      height: 100%;

      &:not(:first-child) {
        margin-left: -25%;
      }
    }
  }

  > .outer > .inner {
    position: absolute;
    width: 94%;
    height: 94%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    clip-path: $hex-clip-path;
  }

  > .outer > .inner > .content {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    position: absolute;
    width: 92%;
    height: 92%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: $deep-sapphire;
    overflow: hidden;
    clip-path: $hex-clip-path;

    > svg {
      width: 60%;
    }

    > .img {
      position: absolute;
      width: 100%;
      object-fit: contain;
    }
  }
}
