@import '@/assets/styles/variables.scss';

.upload-btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;

  list-style-type: none;
  margin-bottom: 0.5rem;
  width: 100%;

  /*ant overrides*/
  .ant-upload {
    width: 100%;
  }

  .btn > .icon {
    padding-right: 0.25rem;
    border-right: 1px solid #fff;
  }

  .btn > .icon + span {
    padding-left: 0.25rem;
  }
}
