@import '@/assets/styles/variables.scss';

.experience-form {
  padding: .5rem;

  > p {
    padding: .5rem;
  }

  > .date {
    display: flex;
    justify-content: space-between;

    > p {
      padding: .5rem;
      text-align: center;
      width: 100%;
    }
  }
}
