@import '@/assets/styles/variables.scss';

.contact-field {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin-bottom: 5px;

  > .wrapper {
    border: 1px solid #d7d7d8;
    line-height: 1rem;
  }

  > .wrapper > .label {
    width: 100%;
    font-size: 1rem;
    color: #9e9e9e;
    border-bottom: 1px solid #ccd2e3;
    padding: 5px 5px 4px;
    max-height: 24px;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .wrapper > .inner {
    display: flex;
    width: 100%;
    align-items: center;
  }

  > .wrapper > .inner > .value {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.875rem;
    padding: 14px 10px;
    max-height: 44px;
    width: calc(100% - 44px);

    &.-flag {
      > span {
        margin-left: 44px;
      }
    }

    > span {
      font-size: 0.875rem;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      color: #000;
    }

    > .flag {
      display: block;
      position: absolute;
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
      max-width: 30px;
    }
  }

  > .wrapper > .inner > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e2f66;

    max-width: 44px;
    min-width: 44px;
    min-height: 44px;
    width: 100%;
    height: 100%;

    > button,
    > a {
      display: flex;
      align-items: center;
    }

    > button > span,
    > a > span {
      font-size: 1.5rem;
      color: #fff;
    }
  }
}
