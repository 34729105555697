@import '../../assets/styles/variables.scss';

.note-form {
  background: $blue-haze;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  padding: 0.8em 0.4em 0 0.4em;

  > .subject-input {
    border-radius: 0;
    background: white;
    color: black;
  }

  > .content-textarea {
    background: white;
    min-height: 6em !important; // Slate is has default mih-height when having a placeholder
    padding: 1em;

    > ol,
    ul {
      padding: 0 1em;

      > li {
        list-style-position: inside;
      }
    }
  }

  > .note-form-accordion > .header {
    gap: 8px;
    box-shadow: none;
    justify-content: space-around;
  }

  > .note-form-accordion > .header > .icon {
    height: 46px;
    width: 46px;
  }

  > .note-form-accordion > .header > .label {
    width: auto;
    display: flex;
    gap: 8px;
    padding: 8px 0px;
  }

  > .note-form-accordion > .body > .body-toolbar {
    display: flex;
    gap: 8px;
    justify-content: end;
  }
}
