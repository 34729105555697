@import '@/assets/styles/variables.scss';

.back-bar {
  width: 100%;
  height: 50px;
  padding: 8px;
  display: flex;
  align-items: center;

  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  > .icon {
    line-height: 32px;
    font-size: 32px;
    color: $deep-sapphire;
  }

  > .label {
    flex: 1;

    font-size: 18px;
    color: $boulder;
  }
}
