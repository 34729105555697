@import '@/assets/styles/variables.scss';

.cropper {
  padding: 1rem;

  > .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  // *NOTE: temporary fix
  &.-browser {
    min-height: 400px;
  }
}

.cropper-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  > label {
    color: #7a7a7a;
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 20px;
  }

  > .tile-container {
    width: 50%;
    margin: 0.5rem auto;
  }

  > .business-card {
    width: 100%;
    min-width: 310px;
    min-height: 165px;
  }

  > .logo {
    position: relative;
    aspect-ratio: 1 / 0.86;
    background-color: #d9d9d9;
    width: 100%;
    height: 100%;

    clip-path: $hex-clip-path;

    > img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
