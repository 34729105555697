@import '@/assets/styles/variables.scss';

.accordion {
  width: 100%;

  > .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 20px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
    }

    > .label {
      color: $boulder;
      font-size: 20px;
      width: 100%;
    }
  }

  > .body {
    display: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  > .body > .actions {
    margin-top: 8px;

    > ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    > ul > li {
      border-left: 1px solid #d7d7d7;
    }
  }

  > .body > .content {
    padding: 10px 0;
  }

  &.-active {
    > .body {
      display: block;
    }
  }
}
