@import '../../assets/styles/variables.scss';

.sidebar-button {
  height: 100%;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: $spacing-md;

  > p {
    font-weight: 300;
    font-size: 16px;
    opacity: 75%;
  }

  &.-white {
    background-color: white;
    color: #07193f;
  }

  &.-blue-haze {
    background-color: $blue-haze;
    color: white;
  }

  &.-inactive {
    background-color: #07193f;
    color: white;
  }

  &.left.-active {
    box-shadow: -4px 0 4px gray;
  }

  &.right.-active {
    margin-left: $spacing-xs;

    box-shadow: 4px 0 4px gray;
  }

  &.right.-shown {
    display: initial;
  }

  &.right.-hidden {
    display: none;
  }

  &.right > p {
    transform: rotate(90deg);
    position: relative;
    top: 38px;
    left: 4px;
  }

  &.left > p {
    transform: rotate(-90deg);
    position: relative;
    top: 34px;
    right: 0px;
  }
}
