@import '@/assets/styles/variables.scss';

.error-fallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 1rem;
  text-align: center;
  margin-bottom: .5rem;

  > .title {
    color: $deep-cove;
    font-size: 6rem;
  }

  > .subtitle {
    color: $boulder;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}