@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin pulse-animation {
  @include keyframes(pulse-animate) {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.5;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  @include animate(pulse-animate, 1s, linear, infinite);
}
