@import '../../assets/styles/variables.scss';

.dropdown-field {
  width: 100%;
  color: $deep-sapphire;
}
.dropdown-label {
  display: block;
  padding-block: $spacing-sm;

  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.dropdown-x {
  width: 100%;
  font-size: 1.125rem;


  >.dropdown-control {
    display: flex;
    background-color: #0c2e78;
    color: white;
    align-items: center;
    padding-left: 13px;

    >.dropdown-placeholder {
      width: 100%;
    }
  }

  .-non-empty {
    background-color: white;
    color: #0c2e78;
  }

}

.dropdown-arrow {
  float: right;
  transform: translateY(1px);
}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(64, 75, 92, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 8px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  right: 10px;
  top: 16px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: rgb(84, 109, 177);
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  border-bottom: 1px solid #ccc; 
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
   border-bottom: none; 
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: $deep-sapphire;
  color: white;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}