@import './variables.scss';

.layout-header {
  background-color: #fff;
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  z-index: 9;
  line-height: 1;

  > .navbar > .dropdown-menu {
    right: 0;
    width: 26%;
  }
}

.layout-left-sider,
.layout-right-sider {
  overflow: auto;
  max-height: calc(100vh - $navbar-height);
}

.layout-left-sider {
  background-color: $deep-cove !important;
  box-shadow: -4px 0 4px inset gray;
  padding-right: 0.5rem;
}

.layout-right-sider {
  background-color: $white !important;
  box-shadow: 4px 0 4px inset gray;
  padding-left: 0.4rem;
}

.layout-content {
  background-color: #fff;
  margin: 0 auto;
  overflow-y: scroll;
  width: 50% !important;
  max-height: calc(100vh - $navbar-height);
  min-height: calc(100vh - $navbar-height);

  &.-full {
    width: 100% !important;
  }

  &.-desktop {
    width: 500px !important;
    margin: 0 auto !important;
  }

  &.-alpha {
    max-height: 100vh;
    height: 100vh;
    min-height: 100vh;
  }

  &.-no-scroll {
    overflow: hidden;
  }

  &:has(.exchange-link-dashboard),
  &:has(.exchange-link-edit) {
    background-color: #07193f;
  }

  &:has(.tutorial-page) {
    display: flex;
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  margin: 0;
  min-height: calc(100vh - $navbar-height);
  width: 100%;

  &.-mobile > img {
    width: 100%;
  }
}

.ant-layout-sider.edit-section > .ant-layout-sider-children {
  background-color: #07193f;
}
