@import '../../assets/styles/variables.scss';

.list-avatar {
  position: relative;
  padding: 8px;
  background: white;
  box-shadow: 0px 4px 4px rgba(92, 92, 92, 0.25);
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.-preview {
    box-shadow: none;
  }

  > .tile-container {
    margin: 0 0.5rem;
    flex: 0 0 25%;
  }

  > .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 8px;
  }

  > .info > p {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    color: $deep-sapphire;
    border-bottom: 1px solid #ccd3e2;
    margin-bottom: 2px;
  }

  > .info > span {
    color: $deep-sapphire;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
  }

  > .rect {
    background: $deep-sapphire;
    height: 47px;
    width: 21px;
  }

  > .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5.5px 6px;

    // height: 100%;
    // width: 75px;
    // height: 73px;

    background-color: $deep-sapphire;
  }

  > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    padding: 14px;

    &::after {
      inset-inline-start: 35%;
    }
  }
}
