@import '@/assets/styles/variables.scss';

.navbar {
  position: sticky;
  top: 0;
  z-index: 1003;
  width: 100%;
  box-shadow: 0 4px 4px gray;

  > .logo-dropdown-toggle {
    position: relative;
    z-index: 1002;
    display: flex;
    justify-content: space-between;
    background-color: white;
  }

  > .logo-dropdown-toggle > .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 0.25rem;
  }

  > .logo-dropdown-toggle > .logo-link > img {
    width: 36px;
    height: 36px;
  }

  > .logo-dropdown-toggle > .right {
    display: flex;
    align-items: center;
  }

  // Antd overrides
  > .logo-dropdown-toggle > .right > .ant-badge {
    > .bell-icon {
      color: $deep-sapphire;
      font-size: 1.6rem;
    }
  }

  > .logo-dropdown-toggle > .right > .material-symbols-outlined,
  > .logo-dropdown-toggle
    > .right
    > .dropdown-toggle
    > .material-symbols-outlined {
    font-weight: 600;
  }

  > .logo-dropdown-toggle > * {
    padding-inline: $spacing-md;
  }

  > .logo-dropdown-toggle > .dropdown-toggle {
    background-color: white;
    border: none;
    cursor: pointer;
  }

  > .dropdown-menu {
    position: absolute;
    z-index: 1001;
    width: 100%;
    height: calc(100vh - $navbar-height);
    padding: $spacing-md;
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    background: $deep-ocean;
    text-transform: capitalize;
    color: white;
    transition-property: opacity, top;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    padding-bottom: 70px;
    overflow-y: scroll;
  }

  > .dropdown-menu.-shown {
    opacity: 1;
    top: $navbar-height;
  }

  > .dropdown-menu.-hidden {
    opacity: 0;
    top: -100vh;
  }
}
