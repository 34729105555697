.level-dropdown {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  > .heading {
    display: flex;
    align-items: center;
  }

  > .heading > p {
    width: 100%;
    background: #0c307e;
    color: white;
    font-size: 16px;
    padding: 12px;
    height: 43px;
    text-align: center;

    &.-selected {
      background: white;
      color: #0c307e;
    }
  }

  > .heading > .button-container {
    cursor: pointer;
  }

  > .heading > .button-container > .icontainer {
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0c307e;
    border-left: 1px solid white;
    color: white;
  }

  > .heading > .button-container > .-active {
    background: white;
    color: #0c307e;
  }

  > .level-menu {
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  > .level-menu > .list {
    font-size: 20px;
    width: 100%;
    padding: 10px;
    border-top: 2px solid #0c307e;
    border-bottom: 2px solid #0c307e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
