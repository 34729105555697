.tag-edit {
  & {
    padding: 10px;
  }

  > .heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  > .heading > .label {
    position: relative;
    text-align: center;
    padding: 10px;
    border: 2px solid #b3bed7;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  > .heading > .label > .title {
    position: relative;
    display: block;
    border-bottom: 1px solid #a1a5bb;
    padding-bottom: 8px;
    width: 100%;
  }

  > .heading > .label > .title > .icon {
    position: absolute;
    right: 0;
    top: 0;
    color: #0e2f66;
    cursor: pointer;
    font-size: 24px;
  }

  > .heading > .label > .info {
    text-align: left;
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    > li {
      font-size: 14px;
    }

    > span {
      color: #bfbfc1;
      margin-top: 20px;
      display: flex;
      align-self: flex-end;
      font-size: 34px;
      cursor: pointer;
    }
  }

  > .lists {
    overflow: hidden;
    overflow-y: scroll;
    min-height: 500px;
    padding: 8px 8px 4px 8px;
    border: 1px solid #eceef8;
    box-shadow: inset 3px 4px 4px rgba(12, 48, 126, 0.37);
  }
}
