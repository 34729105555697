@import '@/assets/styles/variables.scss';

.text-input {
  background-color: $deep-sapphire;
  border-radius: 0;
  color: #fff;
  font-size: 1.125rem;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;

  &::placeholder {
    color: #d9d9d9;
    font-style: italic;
  }

  &.-py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:not([value='']) {
    background-color: #fff;
    color: $deep-sapphire;
  }
}
