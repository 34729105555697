.tagedit-option {
  & {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cacfea;
  }

  > .more {
    font-size: 39px;
    border-right: 1px solid #cacfea;
    width: 41px;
    padding: 5px 0;
    color: #0c2e78;
    cursor: pointer;
  }

  > .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid #cacfea;
    width: 72px;
    padding: 5px 0;
  }

  > .options > .color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  > .options > .expand {
    color: #a1a5bb;
  }

  > .details {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 20px;
  }

  > .details > .label {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  > .details > .edit-label {
    position: absolute;
    display: flex;
    align-items: center;
    top: -12px;
    left: -45px;
  }

  > .details > .edit-label > .input-field {
    width: 287px;
  }

  > .details > .edit-label > .icontainer {
    width: 53px;
    height: 47px;
    background: #0c2e78;
    color: white;
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > .check {
      font-size: 31px;
    }
  }

  > .delete {
    width: 72px;
    padding: 5px;

    text-align: center;
    font-size: 31px;
    color: #8e8ec2;
    border-left: 1px solid #cacfea;
    cursor: pointer;
  }

  > .color-dropdown {
    position: absolute;
    background: white;
    top: -7px;
    left: -7px;
    right: -8px;
    z-index: 1000;

    border-bottom: 1px solid #ccd2e3;
  }

  > .color-dropdown > .heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccd2e3;
    padding: 0px 4px;
  }

  > .color-dropdown > .heading > .toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cacfea;
    width: 54px;
    padding-top: 8px;
    cursor: pointer;

    > .color {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  > .color-dropdown > .heading > .label {
    width: 100%;
    font-size: 20px;
    padding-left: 8px;
  }

  > .color-dropdown > .heading > .close {
    cursor: pointer;
    color: #bfbfc1;
    margin-right: 8px;
  }

  > .color-dropdown > .options {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
  }

  > .color-dropdown > .options > .color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 15px 24px;
    cursor: pointer;
  }
}
