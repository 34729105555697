@import '@/assets/styles/variables.scss';

.menu {
  > .login {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 1rem;
    }

    a {
      color: #000;
      background-color: #fff;
      flex: 1;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > .logout {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    text-transform: none;
    cursor: pointer;

    > p {
      margin-right: 0.5rem;
    }
  }

  > .version {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(230, 230, 230, 0.8);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1.75px;
    margin: 2rem 0;
  }

  > .contact {
    color: rgba(230, 230, 230, 0.8);
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1.75px;
    margin: 2rem 0;
    text-transform: none;
  }
}
