.virtual-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3.5 / 2;

  &.wrapper {
    border: 1px solid grey;

    > .-preview {
      opacity: 1;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &.default {
    height: 100%;
    background: linear-gradient(180deg, #ffffff 29.48%, #d9dfec 100%);
    border: 1px solid #153c7c;
    box-shadow: 3px 4px 4px rgba(12, 46, 120, 0.3);

    > p {
      color: #959494;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
