@import '@/assets/styles/variables.scss';

.image-cropper {
  min-height: calc(100vh - $navbar-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-block-end: 55px;
  margin-inline: 5px;
  background-color: #fff;

  > .cropper-container {
    width: 100%;
    height: 100%;
    padding-inline: 20px;
    background-color: #030c1f;
  }

  > .cta-buttons {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 10px 20px;
  }

  > .cta-buttons > * {
    flex: 1;
  }

  > .cta-buttons > .cancel-btn {
    background-color: #a0a3aa;

    &::before {
      background-image: linear-gradient(
        to top,
        #a0a3aa,
        rgba(255, 255, 255, 0.37)
      );
    }

    &::after {
      background-image: linear-gradient(
        to top,
        #a0a3aa,
        rgba(255, 255, 255, 0)
      );
    }
  }
}
