@import '@/assets/styles/variables.scss';

.modal-overlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;

  &.-mobile {
    max-width: 414px;
  }

  > .modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% + 26.5px));
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    background: white;
  }

  > .modal-content > .body {
    flex: 1;
  }

  > .modal-content.partial {
    width: 380px;
    max-width: 380px;
    height: 285px;
    max-height: 285px;
  }

  > .modal-content.normal {
    width: 50%;
    height: calc(100% - $navbar-height);
  }

  > .modal-content.full {
    width: 100%;
    height: calc(100% - $navbar-height);
  }

  > .modal-content.full > .header > .close-button {
    width: 40px;
    height: 40px;

    background-color: white;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  }
}
