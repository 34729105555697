.date-picker {
  width: 100%;

  /* antd overrides*/
  .ant-picker {
    background-color: #0C2E78;
    border-radius: 0;
    width: 100%;
  }

  .ant-picker-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .ant-picker-input > input, 
  .ant-picker-input > input::placeholder, 
  .ant-picker-input > .ant-picker-suffix {
    color: #fff;
  }

  .ant-picker-input > input {
    font-size: .8rem;
    text-align: center;
  }

  .ant-picker-input > .ant-picker-clear {
    background-color: #0C2E78;
    color: #ccc;
  }
}
