@import '@/assets/styles/variables.scss';

.notes-accordion > .body > .content > .notes-list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > .note-item > .accordion > .note-body > .note-buttons {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  > .note-item .note-header {
    background-color: #fff;
    margin-bottom: 0.75rem;
    padding: 0;
    border: 1px solid #d7d7d8;
    box-shadow: none;

    > .icon:first-child {
      background-color: #f0f8ff;
      border-left: 2px solid #d9dfec;
      box-shadow: none;
      height: 2.875rem;
      width: 6.125rem;
    }

    > .label {
      margin-left: 0;
      max-width: 90%;
    }

    > .label > .value {
      display: flex;
      align-items: center;
    }

    > .label > .value > .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $chetwode-blue;
      font-size: 0.625rem;
      width: 4.5rem;
      height: 2.875rem;
      border-right: 1px solid #cfcfd1;
    }

    > .label > .value > .subject {
      font-size: 0.875rem;
      padding: 16px 10px;
      width: 100%;
    }
  }
}
