@import '@/assets/styles/variables.scss';
@import '@/assets/styles/animation.scss';

.business-logo-upload {
  > .img-container {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    width: 50%;
    margin: 1rem auto;

    > .hex-container {
      position: relative;
      aspect-ratio: 1 / 0.86;
      background-color: #d9d9d9;
      width: 100%;
      height: 100%;

      clip-path: $hex-clip-path;

      &.-animate {
        @include pulse-animation;
      }
    }

    > .hex-container > .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > .hex-container > .inner {
      width: 99%;
      height: 99%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      clip-path: $hex-clip-path;
    }

    > .hex-container > .inner > .content {
      width: 97%;
      height: 97%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $deep-sapphire;

      clip-path: $hex-clip-path;
    }
  }

  > .btn-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $deep-cove;
    padding-block: 1rem;
  }

  > .btn-container > ul {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    list-style: none;
  }

  > .btn-container > ul .icon {
    color: #fff;
    font-size: 1.25rem;
    padding-right: 0.25rem;
    border-right: 1px solid #fff;
  }

  // antd overrides
  > .btn-container .ant-upload {
    width: auto;
  }

  > .btn-container .icon + span {
    padding-left: 0.25rem;
  }
}
