@import './variables.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

body {
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: $font-main;

  .react-toast-notifications__container,
  .css-nvf14r-ToastContainer {
    top: $navbar-height;
  }
}

a {
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  border: none;
  outline: none;
}

input.-cta {
  background-color: $deep-sapphire;
  color: white;
}
input.-non-cta {
  background-color: white;
  color: $deep-sapphire;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: aliceblue;
}

.route-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-width: 414px;
  background: white;

  &.-no-max-width {
    max-width: none;
    overflow: initial;
    height: auto;
  }
}
